import { app } from "@/main";
import { RavenDocumentSettingsUpdateRequest } from "@/models/external-services/raven-api-document-settings-update-request-wrapper";
import { RavenDocumentSettingUpdateRequest } from "@/models/external-services/raven-api-document-settings-update-request";
import { SettingsInterface } from "@/services/settings-services/settings-interface";

class ApplicationSettingsService implements SettingsInterface {
  public get = async () => {
    const apiResponse = await app.$ravenapi.get("api/settings/application/get");
    return apiResponse;
  };

  private update = async (request: RavenDocumentSettingsUpdateRequest) => {
    const apiResponse = await app.$ravenapi.put(
      "api/settings/document/update",
      request
    );
    return apiResponse.data;
  };

  public save = async items => {
    const requests: RavenDocumentSettingUpdateRequest[] = [];
    items.forEach(item => {
      item.forEach(element => {
        const req: RavenDocumentSettingUpdateRequest = new RavenDocumentSettingUpdateRequest(
          element.value,
          element.active,
          element.name
        );
        requests.push(req);
      });
    });
    const request: RavenDocumentSettingsUpdateRequest = new RavenDocumentSettingsUpdateRequest(
      requests
    );

    const results = await this.update(request);
    return results;
  };
}

export const applicationSettingsService = new ApplicationSettingsService();
