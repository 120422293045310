export class RavenDocumentSettingUpdateRequest {
  public Value: string;
  public IsActive: boolean;
  public SettingName: string;

  constructor(value: string, isActive: boolean, settingName: string) {
    this.Value = value;
    this.IsActive = isActive;
    this.SettingName = settingName;
  }
}
