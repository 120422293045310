import { app } from "@/main";
import { SettingsInterface } from "@/services/settings-services/settings-interface";
import { WorktagFieldSetting } from "@/interfaces/settings/worktag-field";

class WorktagFieldsSettingsService implements SettingsInterface {
  public get = async () => {
    const apiResponse = await app.$ravenapi.get("api/breakoutsettings");
    return apiResponse;
  };

  // TO DO - complete save functionality
  public save = async (worktagSettingsList: WorktagFieldSetting[]) => {
    const apiResponse = await app.$ravenapi.post(
      "api/breakoutsettings",
      worktagSettingsList
    );
    return apiResponse;
  };
}

export const worktagFieldsSettingsService = new WorktagFieldsSettingsService();
