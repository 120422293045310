import sidebarSettings from "@/shared/constants/sidebar-settings";
import { documentSettingsService } from "./document-settings.service";
import { applicationSettingsService } from "./application-settings.service";
import { worktagFieldsSettingsService } from "./worktag-fields-settings.service";

export function serviceManager(service: string) {
  let res: any;
  switch (service) {
    case sidebarSettings.documentSettings:
      res = documentSettingsService;
      break;
    case sidebarSettings.applicationSettings:
      res = applicationSettingsService;
      break;
    case sidebarSettings.worktagFieldsSettings:
      res = worktagFieldsSettingsService;
      break;
    default:
      res = () => {};
  }
  return res;
}
